import 'intersection-observer';
import { useSpring } from 'react-spring';
import { useInView } from 'react-intersection-observer';

export function useScrollReveal() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const props = useSpring({
    transform: inView ? `translateY(0%)` : `translateY(10%)`,
    opacity: inView ? 1 : 0,
  });

  return [ref, props];
}
